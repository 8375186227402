
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { useSelector, useDispatch } from "react-redux";
import { Box, ButtonGroup, Button } from "@material-ui/core";
import SearchBar from "./../../src/components/SearchBar";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";
import { NextSeo } from "next-seo";
import {
  SEARCH_PAGE_ROUTE,
  FOR_BUY_PAGE_ROUTE,
  FOR_RENT_PAGE_ROUTE,
  CREATE_LISTING,
  HOST,
} from "./../../src/config/routes";
import FilterComponent from "./../../src/components/Filter/Filter";
import screenSize from "./../../src/states/screen";
import AdditemCardBox from "./../../src/components/AddItemCardBox";
import { POI_TYPE } from "./../../src/config/constants";
import ExploreNeighborhoods from "../../src/components/ExploreNeighborhoods";
import {
  title,
  description,
  generateAlternateLanguages,
} from "../../src/config/seo";

const useStyles = makeStyles((theme) => ({
  searchRoot: {
    backgroundColor: "#E7F5FE",
    width: "100%",
    textTransform: "uppercase",
    margin: 0,
  },
  form: {},
  searchCardHeading: {
    // color: "#FFF",
  },
  searchButton: {
    background: "rgb(217, 60, 35)",
    color: "#FFF",
  },
  buttonGroup: {
    //backgroundColor: "rgba(59, 65, 68, 0.6)",
  },
  buttonStyle: {
    flex: "1",
  },
  buttonStyleRoomRental: {
    flex: "3",
  },
  addBoxHeading: {
    marginBottom: theme.spacing(2),
  },
  addBoxContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formFieldWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

export default function Index({ city }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const router = useRouter();
  const isDesktop = screenSize();
  const { t } = useTranslation("common");

  const [filterValues, setFilterValues] = useState({
    city: city,
    minPrice: null,
    maxPrice: null,
    bedroom: null,
    bathroom: null,
    propertyType: null,
    type: "sale",
    autocompleteData: [],
  });

  const setSeletedType = (value) => {
    setFilterValues({
      ...filterValues,
      type: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const { autocompleteData, ...filterVal } = filterValues;
    const filter = {};
    for (let i in filterVal) {
      if (filterVal[i]) {
        filter[i] = filterVal[i];
      }
    }

    const poiId = [];
    const propertyId = [];

    if (autocompleteData && autocompleteData.length) {
      for (let i = 0; i < autocompleteData.length; i++) {
        let id = autocompleteData[i]._id;
        if (POI_TYPE.includes(autocompleteData[i].type)) {
          poiId.push(id);
        } else {
          propertyId.push(id);
        }
      }
    }

    router.push({
      pathname: SEARCH_PAGE_ROUTE,
      query: {
        type: filterValues.type.toLowerCase(),
        poiId,
        propertyId,
        ...filter,
      },
    });
  };

  const pageTitle = title[city] ? title[city].home : title.home;
  const pageDescription = description[city]
    ? description[city].home
    : description.home;
  return (
    <Container maxWidth={false} disableGutters={true}>
      <NextSeo
        title={t(pageTitle)}
        description={t(pageDescription)}
        canonical={`${HOST}/${city}`}
        languageAlternates={generateAlternateLanguages(`/${city}`)}
        openGraph={{
          url: `${HOST}/${city}`,
          type: "website",
          title: pageTitle,
          description: pageDescription,
          images: [
            {
              url: `${HOST}/logo.png`,
              width: 200,
              height: 200,
              alt: "Property Glassdoor",
            },
          ],
        }}
      />
      <Box my={1} p={3} className={classes.searchRoot} disableGutters={true}>
        <Container maxWidth="md" disableGutters={isDesktop ? false : true}>
          <h1 style={{ textIndent: -5000, height: 0, margin: 0 }}>
            {`Property Glassdoor ${city}`}
          </h1>
          <form onSubmit={handleFormSubmit} className={classes.form}>
            <Grid container xs={12}>
              <Grid item xs={12} sm={12} className={classes.formFieldWrapper}>
                <Typography
                  className={classes.searchCardHeading}
                  variant="h4"
                  component="h2"
                >
                  {filterValues.type === "sale"
                    ? t("find_property_for_sale")
                    : null}
                  {filterValues.type === "rent"
                    ? t("find_property_for_rent")
                    : null}
                  {filterValues.type === "room-rental"
                    ? t("find_room_rental")
                    : null}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.formFieldWrapper}
                disableGutters={true}
              >
                <ButtonGroup
                  size={"medium"}
                  classes={classes.buttonGroup}
                  fullWidth
                  disableElevation
                  variant="contained"
                  color="default"
                >
                  <Button
                    variant={filterValues.type === "sale" ? "contained" : ""}
                    color={filterValues.type === "sale" ? "secondary" : ""}
                    className={classes.buttonStyle}
                    onClick={() => {
                      setSeletedType("sale");
                    }}
                  >
                    {t("buy")}
                  </Button>
                  <Button
                    variant={filterValues.type === "rent" ? "contained" : ""}
                    color={filterValues.type === "rent" ? "secondary" : ""}
                    className={classes.buttonStyle}
                    onClick={() => {
                      setSeletedType("rent");
                    }}
                  >
                    {t("rent")}
                  </Button>
                  <Button
                    variant={
                      filterValues.type === "room-rental" ? "contained" : ""
                    }
                    color={
                      filterValues.type === "room-rental" ? "secondary" : ""
                    }
                    className={classes.buttonStyleRoomRental}
                    onClick={() => {
                      setSeletedType("room-rental");
                    }}
                  >
                    {t("room_rental")}
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                className={classes.formFieldWrapper}
                disableGutters={true}
              >
                <FilterComponent
                  hideListingType={true}
                  filterValues={filterValues}
                  setFilterValues={setFilterValues}
                  showCity={false}
                />
              </Grid>
              <Hidden smUp>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className={classes.formFieldWrapper}
                  disableGutters={true}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="primary"
                  >
                    {t("search")}
                  </Button>
                </Grid>
              </Hidden>
            </Grid>
          </form>
        </Container>
      </Box>

      <Container maxWidth="md" className={classes.addBoxContainer}>
        <Grid item xs={12} align="center" className={classes.addBoxHeading}>
          <Typography variant="h4" component="h2">
            {t("see_how_property_glassdoor_can_help")}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box p={2}>
              <AdditemCardBox
                title={t("buy_a_home")}
                description={t("buy_a_home_description")}
                buttonLabel={t("find_a_home")}
                buttonActionUrl={`${city}${FOR_BUY_PAGE_ROUTE}`}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={2}>
              <AdditemCardBox
                title={t("rent_a_home")}
                description={t("rent_a_home_description")}
                buttonLabel={t("find_a_rental")}
                buttonActionUrl={`${city}${FOR_RENT_PAGE_ROUTE}`}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box p={2}>
              <AdditemCardBox
                title={t("sell_your_home")}
                description={t("sell_your_home_description")}
                buttonLabel={t("sell_home")}
                buttonActionUrl={CREATE_LISTING}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} className={classes.addBoxContainer}>
        <ExploreNeighborhoods city={city} />
      </Container>
    </Container>
  );
}

// export const config = { amp: "hybrid" };


    export async function getServerSideProps(ctx) {
        
        
        return {
          
          
          props: {
            
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[city]/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  